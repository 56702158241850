import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/getMainShop";
import FollowGet from "@/store/analysis/follow/get";
import FollowChart from "@/views/analysis/FollowChart.vue";
import { FollowGetRequest } from "@/api/analysis/follow/request";
import Admin from "@/store/admin/admin";

export interface FollowInputOptions {
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    FollowChart
  }
})
export default class AnalysisFollow extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "フォロー";
  headingSub = "Number of followers";
  breadCrumbs = [{ text: "フォロー数", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedFollowData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  inputOptions: FollowInputOptions = {
    selectShop: null
  };

  totalCountOnDrillDowned = 0;

  /**
   * 総件数
   */
  get totalCount() {
    return this.totalCountOnDrillDowned > 0
      ? this.totalCountOnDrillDowned
      : FollowGet.getTotalCount;
  }

  get followData() {
    return FollowGet.getData;
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      // NEW_DEV-2121 cyber start
      ShopGet.getItems.sort(function (obj1, obj2) {
        const val1 = obj1.id;
        const val2 = obj2.id;
          if (val1 < val2) {
            return -1;
          } else if (val1 > val2) {
            return 1;
          } else {
            return 0;
          }
      })
       // NEW_DEV-2121 cyber end
      this.inputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchRootChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await FollowGet.clearResponse();
  }

  async fetchRootChartData() {
    this.isLoadedFollowData = false;
    await this.fetchFollow();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchFollow(): Promise<boolean> {
    this.isLoadedFollowData = false;
    await Flash.clear();
    const followGetRequest: FollowGetRequest = {
      searchShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null
    };
    await FollowGet.get(followGetRequest);
    if (!FollowGet.isSuccess) {
      await Flash.setErrorNow({
        message: FollowGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedFollowData = true;
    return FollowGet.isSuccess;
  }

  updateTotalCountOnDrillDowned(value: number) {
    this.totalCountOnDrillDowned = value;
  }
}

import service from "@/api/service";
import { FollowGetRequest } from "@/api/analysis/follow/request";
import { FollowGetResponse } from "@/api/analysis/follow/response";

/**
 * フォローAPIをコールします。
 *
 * @param getRequest フォローAPIのリクエストボディ
 * @return FollowGetResponse
 */
export async function get(getRequest: FollowGetRequest) {
  const response = await service.post("/analysis-follow", getRequest);
  return response.data as FollowGetResponse;
}

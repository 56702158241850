import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import Flash, { ErrorAlert } from "@/store/common/flash";
import AnalysisBase from "@/models/analysis/analysis-base";
import { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import { FollowProfileGetRequest } from "@/api/analysis/follow-profile/request";
import { FollowInputOptions } from "@/models/analysis/follow";
import * as FollowUserCSVGet from "@/api/analysis/follow-user-csv";
import { isSuccess } from "@/api/response";
import { FollowUserCSVGetRequest } from "@/api/analysis/follow-user-csv/request";
import FollowProfileGet from "@/store/analysis/follow-profile/get";
import FollowGet from "@/store/analysis/follow/get";

@Component({
  components: {
    UlBarChart: UlBarChart,
    UlPieChart: UlPieChart
  }
})
export default class AnalysisFollowChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: FollowInputOptions;
  @Prop() data!: AnalysisGraphData;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  chart: Record<string, any> = {};

  // ローディングステータス
  isLoadedFollowProfile = false;

  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;

  get followChartData() {
    const chartData = this.getBarChartData(this.data);
    if (chartData && chartData.datasets) {
      chartData.datasets.map(d => {
        d.label = "支店別フォロー数";
      });
    }
    return chartData;
  }

  get followChartOption() {
    return this.getBarChartOptions(
      this.data,
      false,
      true,
      this.drillDownFollowProfile
    );
  }

  get genderChartData() {
    return this.getPieChartData(FollowProfileGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(
      FollowProfileGet.getGenderData,
      this.fetchFollowUserCSVByGender
    );
  }

  get periodChartData() {
    return this.getPieChartData(FollowProfileGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(
      FollowProfileGet.getPeriodData,
      this.fetchFollowUserCSVByPeriod
    );
  }

  get profile1ChartData() {
    return this.getPieChartData(FollowProfileGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(
      FollowProfileGet.getProfile1Data,
      this.fetchFollowUserCSVByProfile1
    );
  }

  get profile2ChartData() {
    return this.getPieChartData(FollowProfileGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(
      FollowProfileGet.getProfile2Data,
      this.fetchFollowUserCSVByProfile2
    );
  }

  get profile3ChartData() {
    return this.getPieChartData(FollowProfileGet.getProfile3Data);
  }
  
  // NEW_DEV-2120 cyber start
  get profile3ChartOptions() {
    return this.getPieChartOptions(
      FollowProfileGet.getProfile3Data,
      this.fetchFollowUserCSVByProfile3
    );
  }
  // NEW_DEV-2120 cyber end

  get profile4ChartData() {
    return this.getPieChartData(FollowProfileGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(
      FollowProfileGet.getProfile4Data,
      this.fetchFollowUserCSVByProfile4
    );
  }

  get profile5ChartData() {
    return this.getPieChartData(FollowProfileGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(
      FollowProfileGet.getProfile5Data,
      this.fetchFollowUserCSVByProfile5
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async fetchFollowProfile(
    inputOptions: FollowProfileGetRequest
  ): Promise<boolean> {
    this.isLoadedFollowProfile = false;
    await Flash.clear();
    await FollowProfileGet.get(inputOptions);
    if (!FollowProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: FollowProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedFollowProfile = true;
    return FollowProfileGet.isSuccess;
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.drillDownHierarchy = 0;
  }

  async drillDownFollowProfile(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.inputOptions.selectShop == null ||
      this.isDashboard
    ) {
      return;
    }

    const element = this.chart.getElementAtEvent(event)[0] as ChartElement;
    if (event.ctrlKey || event.shiftKey) {
      await this.fetchFollowUserCSV(0, element._index);
      return;
    }

    const followProfileGetRequest: FollowProfileGetRequest = {
      date: FollowGet.getDate,
      shopId: Number(this.data.labelsData[element._index])
    };

    await this.fetchFollowProfile(followProfileGetRequest);

    const data = this.data.datasets[element._datasetIndex].data;
    if (data != null) {
      this.totalCountOnDrillDowned = Number(data[element._index]);
    }
    this.drillDownHierarchy = 1;
  }

  async fetchFollowUserCSV(graphType: number, elementIndex: number) {
    const followUserCSVGetRequest: FollowUserCSVGetRequest = {
      shopId: Number(FollowProfileGet.getResult.shopId),
      mainShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : 0,
      graphType: graphType
    };

    if (graphType == 1) {
      followUserCSVGetRequest.profileData =
        FollowProfileGet.getResult.profile1Data.data.labels[elementIndex];
    } else if (graphType == 2) {
      followUserCSVGetRequest.profileData =
        FollowProfileGet.getResult.profile2Data.data.labels[elementIndex];
    } else if (graphType == 3) {
      followUserCSVGetRequest.profileData =
        FollowProfileGet.getResult.profile3Data.data.labels[elementIndex];
    } else if (graphType == 4) {
      followUserCSVGetRequest.profileData =
        FollowProfileGet.getResult.profile4Data.data.labels[elementIndex];
    } else if (graphType == 5) {
      followUserCSVGetRequest.profileData =
        FollowProfileGet.getResult.profile5Data.data.labels[elementIndex];
    } else if (graphType == 6) {
      followUserCSVGetRequest.profileData =
        FollowProfileGet.getResult.genderData.data.labels[elementIndex];
    } else if (graphType == 7) {
      followUserCSVGetRequest.profileData =
        FollowProfileGet.getResult.periodData.data.labels[elementIndex];
    }

    const followUserCSVGetResponse = await FollowUserCSVGet.get(
      followUserCSVGetRequest
    );

    if (followUserCSVGetResponse && followUserCSVGetResponse.statusCd !== 200) {
      await Flash.setErrorNow({
        message: followUserCSVGetResponse.message,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      followUserCSVGetResponse.results.csvString,
      followUserCSVGetResponse.results.fileName
    );
  }

  async fetchFollowUserCSVByProfile1(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchFollowUserCSVByProfile(1, 2, event, activeElements);
  }

  async fetchFollowUserCSVByProfile2(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchFollowUserCSVByProfile(2, 2, event, activeElements);
  }

  async fetchFollowUserCSVByProfile3(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchFollowUserCSVByProfile(3, 2, event, activeElements);
  }

  async fetchFollowUserCSVByProfile4(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchFollowUserCSVByProfile(4, 2, event, activeElements);
  }

  async fetchFollowUserCSVByProfile5(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchFollowUserCSVByProfile(5, 2, event, activeElements);
  }

  async fetchFollowUserCSVByGender(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchFollowUserCSVByProfile(6, 2, event, activeElements);
  }

  async fetchFollowUserCSVByPeriod(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchFollowUserCSVByProfile(7, 2, event, activeElements);
  }

  async fetchFollowUserCSVByProfile(
    graphType: number,
    profileType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchFollowUserCSV(graphType, element._index);
    }
  }

  chartRendered(chart: Record<string, any>) {
    this.chart = chart;
  }
}

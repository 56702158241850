import service from "@/api/service";
import { FollowProfileGetRequest } from "@/api/analysis/follow-profile/request";
import { FollowProfileGetResponse } from "@/api/analysis/follow-profile/response";

/**
 * プロフィール属性(フォロー)APIをコールします。
 *
 * @param getRequest プロフィール属性(フォロー)APIのリクエストボディ
 * @return FollowProfileGetResponse
 */
export async function get(getRequest: FollowProfileGetRequest) {
  const response = await service.post("/analysis-follow-profile", getRequest);
  return response.data as FollowProfileGetResponse;
}
